.about-container {
  padding: 10vh 5vw;
  display: flex;
}

.about-desc-container {
  padding-right: 10vw;
  width: 60vw;
}

.about-title {
  font-size: 3vw;
  font-weight: bold;
  margin-bottom: 10vh;
}

.about-desc {
  font-size: 1vw;
  font-weight: lighter;
}
.about-video-container {
  position: relative;
  overflow: hidden;
  width: 50%;
  padding-top: 25.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  height: 50vh;
}

.video-frame {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

@media (orientation: portrait) {
  .about-container{
    flex-direction: column;
  }

  .about-desc-container {
    padding-right: 0;
    width: 100%;
    padding: 5vw;
  }

  .about-title {
    text-align: center;
    font-size: 7vw;
    margin-bottom: 5vw;
  }

  .about-desc {
    text-align: center;
    font-size: 3vw;
  }

  .about-video-container {
    width: 100%;
  }

}