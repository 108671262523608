.round-bg-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -999;
    overflow: hidden;
}

.container3 {
    height: 120%;
}

.round-bg {
    height: calc(10vh + 10vw / 2);
    width: calc(10vh + 10vw / 2);
    background-color: #930000;
    border-radius: 50%;
    position: absolute;
}

.bg1 {
    width: calc(50vh + 50vw / 2);
    height: calc(50vh + 50vw / 2);
    top: -10vw;
    right: -10vw;
}

.bg2 {
    width: calc(30vh + 30vw / 2);
    height: calc(30vh + 30vw / 2);
    left: -15vw;
}

.bg3 {
    width: calc(30vh + 30vw / 2);
    height: calc(30vh + 30vw / 2);
    right: -15vw;
    bottom: 0vh;
}

.bg4 {
    width: calc(30vh + 30vw / 2);
    height: calc(30vh + 30vw / 2);
    top: 0vh;
    right: 0vw;
    z-index: -999;
}

@media (orientation: portrait) {
    .bg1 {
        width: calc(30vh + 30vw / 2);
        height: calc(30vh + 30vw / 2);
        top: calc(-15vh + -15vw / 2);
        right: calc(-15vh + -15vw / 2);
    }

    .bg2 {
        width: calc(40vh + 40vw / 2);
        height: calc(40vh + 40vw / 2);
        top: 15vh;
        left: -30vw;
    }

    .bg3 {
        bottom: auto;
        top: 0;
        right: -40vw;
    }
}