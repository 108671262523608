.nav-wrapper {
    width: 100%;
    height: 10vw;
    display: flex;
    transition: all 0.25s ease-out 0s;
    z-index: 99;
}

.left-container {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.right-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.toggler {
    display: none;
    width: 7vw;
    margin-left: 5vw;
}

.menu-container {
    display: flex;
    justify-content: center;
    margin-left: 5vw;
}

.menu {
    margin: 0 2vw;
    color: #C4C4C4;
    font-size: 1vw;
}

.menu:hover {
    color: #930000;
    text-decoration: none;
}

.logo {
    margin-right: 10vw;
    width: 5vw;
}

.sidebar-container {
    display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.sidebar {
    position: absolute;
    height: 100%;
    width: 90vw;
    left: -90vw;
    background-color: #930000;
    transition: all 0.2s ease-out;
}

.sidebar-head {
    height: 15%;
    display: flex;
}

.sidebar-logo-container {
    flex: 1;
    display: flex;
    align-items: center;
}

.sidebar-logo {
    height: 9vw;
    margin-left: 7vw;
}

.close-button-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.close-button {
    height: 5vw;
    margin-right: 7vw;
}

.sidebar-menu-container {
    display: flex;
    flex-direction: column;
    padding-left: 7vw;
}

.side-menu {
    display: flex;
    align-items: center;
    font-size: 4vw;
    color: #C4C4C4;
    height: 8vh;
}

.side-menu:active,
.side-menu:focus
{
    text-decoration: none;
}

@media (orientation: portrait) {

    .nav-wrapper {
        height: 10vh;
    }

    .toggler {
        display: inline;
    }

    .menu-container {
        display: none;
    }

    .logo {
        width: 12vw;
    }
}
