.section1-container {
    padding: 15vh 7vw;
    display: flex;
    /* height: 100vh; */
}

.section1-description-container {
    flex: 1;
}

.section1-title {
    font-size: 5vw;
    font-weight: bold;
    margin-bottom: 10vh;
}
.section1-desc {
    font-size: 2vw;
    font-weight: lighter;
    margin-bottom: 10vh;
}

.mobile-app-container {
    flex: 1;
    max-width: 100%;
    max-height: 100%;
}

.download-container-desktop {
    display: flex;
    max-width: 100%;
}

.download-container-mobile {
    display: none;
}

.mobile-app-img {
    display: block;
    max-width: 80%;
    max-height: 80%;
    margin-left: 20%;
}

.download-img {
    max-width: 50%;
    width: 35%;
    padding: 0 5vw 0 0;
}

@media (orientation: portrait) {

    .section1-container {
        padding: 15vh 10vw;
        flex-direction: column;
    }

    .download-container-desktop {
        display: none;
    }

    .download-container-mobile {
        display: block;
    }

    .section1-title {
        text-align: center;
        font-size: 8vw;
    }

    .section1-desc {
        text-align: center;
        font-size: 5vw;
        margin-bottom: 2vh;
    }

    .mobile-app-img {
        margin-bottom: 5vh;
        margin-left: 10%;
    }

    .download-img {
        max-width: 50%;
        width: 50%;
        padding: 0 5vw;
    }
}