.section2-container {
    padding: 15vh 7vw;
    display: flex;
}

.steg-container {
    flex: 1.5;
}

.steg-img {
    max-width: 80%;
    margin-bottom: 8vh;
}

.arrow-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10vw;
}

.arrow-icon {
    max-width: 100%;
    width: 3vw;
}

.steg-title {
    font-weight: bold;
    font-size: 3.5vw;
    margin-bottom: 3vh;
}

.steg-desc {
    font-size: 1.5vw;
    font-weight: lighter;
}

@media (orientation: portrait) {
    .section2-container {
        flex-direction: column;
        padding: 10vh 19vw;
    }

    .steg-container {
        text-align: center;
        margin: 10vh 0;
    }

    .steg-img {
        max-width: 80%;
    }

    .steg-title {
        font-size: 7vw;
    }

    .steg-desc {
        font-size: 5vw;
    }

    .arrow-container {
        padding-bottom: 0;
    }

    .arrow-icon {
        transform: rotate(90deg);
        width: 8vw;
    }
}