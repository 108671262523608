.section4-container {
    padding: 15vh 7vw;
    display: flex;
}

.swish-number-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.swish-number {
    font-weight: bold;
    font-size: 5vw;
    margin: 0 auto;
}

.swish-logo {
    width: 70%;
    margin: 0 auto;
}

.swish-desc-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.swish-title {
    font-size: 4.5vw;
    font-weight: bold;
    margin-bottom: 2vw;
}

.swish-desc {
    font-size: 1.5vw  ;
    font-weight: lighter;
}

@media (orientation: portrait) {

    .section4-container {
        flex-direction: column;
    }

    .swish-number-container {
        margin-bottom: 20vh;
    }

    .swish-number {
        font-size: 8vw;
        margin-bottom: 5vh;
    }

    .desc-img {
        width: 60%;
    }

    .swish-title {
        margin: 0 auto 5vh auto;
        font-size: 7vw;
    }

    .swish-desc {
        font-size: 4vw;
        text-align: center;
    }
}