.section3-container {
    padding: 15vh 7vw;
    display: flex;
}

.description-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 8vw;
}

.desc-img {
    max-width: 70%;
    margin: 0 auto 5vh auto;
}

.section-title {
    font-weight: bold;
    font-size: 4vw;
    margin-bottom: 5vh;
}

.desc {
    font-weight: lighter;
    font-size: 1.5vw;
}

.form-container {
    flex: 1;
    padding-top: 15vh;
}

.section-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 4%;
    box-shadow: 0 0 1vw rgba(0, 0, 0, 0.2);
    background-color: white;
    padding: 4vh 4vw;
}

.form-title {
    font-weight: bold;
    font-size: 2vw;
    margin-bottom: 5vh;
}

::placeholder {
    color: black;
}

::-ms-input-placeholder {
    color: black;
}

.form-input {
    font-size: 1.3vw;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-width: 1.2px;
    border-color:  #930000;
    margin-bottom: 5vh;
    outline: none;
}

.form-textarea {
    font-size: 1.3vw;
    height: 15vh;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-width: 1.2px;
    border-color:  #930000;
    margin-bottom: 5vh;
    outline: none;
}

.checkbox-and-desc-container {
    display: flex;
    margin-bottom: 5vh;
}

.checkbox-container {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 1vw;
}

.form-checkbox {
    -ms-transform: scale(3);
    -moz-transform: scale(3);
    -webkit-transform: scale(3);
    -o-transform: scale(3);
    transform: scale(3);
    outline: none;
}

.form-checkbox-desc {
    font-size: 1vw;
    font-weight: bold;
    flex: 9;
}

.form-submit-button {
    border: none;
    border-radius: 25px;
    width: 15vw;
    height: 6vh;
    margin: 0 auto;
    background-color:  #930000;
    color: white;
    font-size: 1.5vw;
}

@media (orientation: portrait) {
    .section3-container {
        flex-direction: column;
    }

    .description-container {
        align-items: center;
        padding: 0;
        margin-bottom: 10vh;
    }

    .desc-img {
        max-width: 90%;
    }

    .section-title {
        font-size: 6vw;
    }

    .desc {
        font-size: 3.5vw;
        text-align: center;
    }

    .form-container {
        padding-top: 10vh;
    }

    .section-form {
        box-shadow: 0 0 5vw rgba(0, 0, 0, 0.3);
        padding: 5vh 5vw;
    }

    .form-title {
        font-size: 4vw;
    }

    .form-input {
        font-size: 4vw;
        border-width: 2px;
    }

    .form-textarea {
        font-size: 4vw;
        border-width: 2px;
    }

    .checkbox-and-desc-container {
        height: 10vh;
    }

    .checkbox-container {
        padding: 0;
        justify-content: center;
    }

    .form-checkbox {
        -ms-transform: scale(3);
        -moz-transform: scale(3);
        -webkit-transform: scale(3);
        -o-transform: scale(3);
        transform: scale(3);
    }

    .form-checkbox-desc {
        flex: 4;
        font-size: 3vw;
        display: flex;
        align-items: center;
    }

    .form-submit-button {
        width: 60vw;
        height: 8vh;
        border-radius: 10vw;
        font-size: 5vw;
    }
}