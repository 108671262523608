@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500&display=swap);
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.section1-container {
    padding: 15vh 7vw;
    display: flex;
    /* height: 100vh; */
}

.section1-description-container {
    flex: 1 1;
}

.section1-title {
    font-size: 5vw;
    font-weight: bold;
    margin-bottom: 10vh;
}
.section1-desc {
    font-size: 2vw;
    font-weight: lighter;
    margin-bottom: 10vh;
}

.mobile-app-container {
    flex: 1 1;
    max-width: 100%;
    max-height: 100%;
}

.download-container-desktop {
    display: flex;
    max-width: 100%;
}

.download-container-mobile {
    display: none;
}

.mobile-app-img {
    display: block;
    max-width: 80%;
    max-height: 80%;
    margin-left: 20%;
}

.download-img {
    max-width: 50%;
    width: 35%;
    padding: 0 5vw 0 0;
}

@media (orientation: portrait) {

    .section1-container {
        padding: 15vh 10vw;
        flex-direction: column;
    }

    .download-container-desktop {
        display: none;
    }

    .download-container-mobile {
        display: block;
    }

    .section1-title {
        text-align: center;
        font-size: 8vw;
    }

    .section1-desc {
        text-align: center;
        font-size: 5vw;
        margin-bottom: 2vh;
    }

    .mobile-app-img {
        margin-bottom: 5vh;
        margin-left: 10%;
    }

    .download-img {
        max-width: 50%;
        width: 50%;
        padding: 0 5vw;
    }
}
.round-bg-container {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -999;
    overflow: hidden;
}

.container3 {
    height: 120%;
}

.round-bg {
    height: calc(10vh + 10vw / 2);
    width: calc(10vh + 10vw / 2);
    background-color: #930000;
    border-radius: 50%;
    position: absolute;
}

.bg1 {
    width: calc(50vh + 50vw / 2);
    height: calc(50vh + 50vw / 2);
    top: -10vw;
    right: -10vw;
}

.bg2 {
    width: calc(30vh + 30vw / 2);
    height: calc(30vh + 30vw / 2);
    left: -15vw;
}

.bg3 {
    width: calc(30vh + 30vw / 2);
    height: calc(30vh + 30vw / 2);
    right: -15vw;
    bottom: 0vh;
}

.bg4 {
    width: calc(30vh + 30vw / 2);
    height: calc(30vh + 30vw / 2);
    top: 0vh;
    right: 0vw;
    z-index: -999;
}

@media (orientation: portrait) {
    .bg1 {
        width: calc(30vh + 30vw / 2);
        height: calc(30vh + 30vw / 2);
        top: calc(-15vh + -15vw / 2);
        right: calc(-15vh + -15vw / 2);
    }

    .bg2 {
        width: calc(40vh + 40vw / 2);
        height: calc(40vh + 40vw / 2);
        top: 15vh;
        left: -30vw;
    }

    .bg3 {
        bottom: auto;
        top: 0;
        right: -40vw;
    }
}
.section2-container {
    padding: 15vh 7vw;
    display: flex;
}

.steg-container {
    flex: 1.5 1;
}

.steg-img {
    max-width: 80%;
    margin-bottom: 8vh;
}

.arrow-container {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10vw;
}

.arrow-icon {
    max-width: 100%;
    width: 3vw;
}

.steg-title {
    font-weight: bold;
    font-size: 3.5vw;
    margin-bottom: 3vh;
}

.steg-desc {
    font-size: 1.5vw;
    font-weight: lighter;
}

@media (orientation: portrait) {
    .section2-container {
        flex-direction: column;
        padding: 10vh 19vw;
    }

    .steg-container {
        text-align: center;
        margin: 10vh 0;
    }

    .steg-img {
        max-width: 80%;
    }

    .steg-title {
        font-size: 7vw;
    }

    .steg-desc {
        font-size: 5vw;
    }

    .arrow-container {
        padding-bottom: 0;
    }

    .arrow-icon {
        transform: rotate(90deg);
        width: 8vw;
    }
}
.section3-container {
    padding: 15vh 7vw;
    display: flex;
}

.description-container {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 8vw;
}

.desc-img {
    max-width: 70%;
    margin: 0 auto 5vh auto;
}

.section-title {
    font-weight: bold;
    font-size: 4vw;
    margin-bottom: 5vh;
}

.desc {
    font-weight: lighter;
    font-size: 1.5vw;
}

.form-container {
    flex: 1 1;
    padding-top: 15vh;
}

.section-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 4%;
    box-shadow: 0 0 1vw rgba(0, 0, 0, 0.2);
    background-color: white;
    padding: 4vh 4vw;
}

.form-title {
    font-weight: bold;
    font-size: 2vw;
    margin-bottom: 5vh;
}

::-webkit-input-placeholder {
    color: black;
}

:-ms-input-placeholder {
    color: black;
}

::-ms-input-placeholder {
    color: black;
}

::placeholder {
    color: black;
}

::-ms-input-placeholder {
    color: black;
}

.form-input {
    font-size: 1.3vw;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-width: 1.2px;
    border-color:  #930000;
    margin-bottom: 5vh;
    outline: none;
}

.form-textarea {
    font-size: 1.3vw;
    height: 15vh;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-width: 1.2px;
    border-color:  #930000;
    margin-bottom: 5vh;
    outline: none;
}

.checkbox-and-desc-container {
    display: flex;
    margin-bottom: 5vh;
}

.checkbox-container {
    flex: 1 1;
    display: flex;
    align-items: center;
    padding-left: 1vw;
}

.form-checkbox {
    transform: scale(3);
    outline: none;
}

.form-checkbox-desc {
    font-size: 1vw;
    font-weight: bold;
    flex: 9 1;
}

.form-submit-button {
    border: none;
    border-radius: 25px;
    width: 15vw;
    height: 6vh;
    margin: 0 auto;
    background-color:  #930000;
    color: white;
    font-size: 1.5vw;
}

@media (orientation: portrait) {
    .section3-container {
        flex-direction: column;
    }

    .description-container {
        align-items: center;
        padding: 0;
        margin-bottom: 10vh;
    }

    .desc-img {
        max-width: 90%;
    }

    .section-title {
        font-size: 6vw;
    }

    .desc {
        font-size: 3.5vw;
        text-align: center;
    }

    .form-container {
        padding-top: 10vh;
    }

    .section-form {
        box-shadow: 0 0 5vw rgba(0, 0, 0, 0.3);
        padding: 5vh 5vw;
    }

    .form-title {
        font-size: 4vw;
    }

    .form-input {
        font-size: 4vw;
        border-width: 2px;
    }

    .form-textarea {
        font-size: 4vw;
        border-width: 2px;
    }

    .checkbox-and-desc-container {
        height: 10vh;
    }

    .checkbox-container {
        padding: 0;
        justify-content: center;
    }

    .form-checkbox {
        transform: scale(3);
    }

    .form-checkbox-desc {
        flex: 4 1;
        font-size: 3vw;
        display: flex;
        align-items: center;
    }

    .form-submit-button {
        width: 60vw;
        height: 8vh;
        border-radius: 10vw;
        font-size: 5vw;
    }
}
.section4-container {
    padding: 15vh 7vw;
    display: flex;
}

.swish-number-container {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.swish-number {
    font-weight: bold;
    font-size: 5vw;
    margin: 0 auto;
}

.swish-logo {
    width: 70%;
    margin: 0 auto;
}

.swish-desc-container {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.swish-title {
    font-size: 4.5vw;
    font-weight: bold;
    margin-bottom: 2vw;
}

.swish-desc {
    font-size: 1.5vw  ;
    font-weight: lighter;
}

@media (orientation: portrait) {

    .section4-container {
        flex-direction: column;
    }

    .swish-number-container {
        margin-bottom: 20vh;
    }

    .swish-number {
        font-size: 8vw;
        margin-bottom: 5vh;
    }

    .desc-img {
        width: 60%;
    }

    .swish-title {
        margin: 0 auto 5vh auto;
        font-size: 7vw;
    }

    .swish-desc {
        font-size: 4vw;
        text-align: center;
    }
}
.about-container {
  padding: 10vh 5vw;
  display: flex;
}

.about-desc-container {
  padding-right: 10vw;
  width: 60vw;
}

.about-title {
  font-size: 3vw;
  font-weight: bold;
  margin-bottom: 10vh;
}

.about-desc {
  font-size: 1vw;
  font-weight: lighter;
}
.about-video-container {
  position: relative;
  overflow: hidden;
  width: 50%;
  padding-top: 25.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
  height: 50vh;
}

.video-frame {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

@media (orientation: portrait) {
  .about-container{
    flex-direction: column;
  }

  .about-desc-container {
    padding-right: 0;
    width: 100%;
    padding: 5vw;
  }

  .about-title {
    text-align: center;
    font-size: 7vw;
    margin-bottom: 5vw;
  }

  .about-desc {
    text-align: center;
    font-size: 3vw;
  }

  .about-video-container {
    width: 100%;
  }

}
/* main {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
  #form-container {
    width: 445px;
    height: 585px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    padding: 30px 35px;
  }
  
  #form-container label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 35px;
  }
  
  #form-container input,
  #form-container input:focus,
  #form-container textarea,
  #form-container textarea:focus,
  #form-container .form-control:focus {
    border-top: none;
    border-left: none;
    border-right: none;
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid #930000;
    padding-left: 0;
  }
  
  #form-container input:focus::placeholder,
  #form-container textarea:focus::placeholder {
    color: transparent !important;
  }
  
  #form-container ::placeholder {
    color: #000000 !important;
  }
  
  #form-container :-ms-input-placeholder {
    color: #000000;
  }
  
  #form-container ::-ms-input-placeholder {
    color: #000000;
  }
  
  #form-container input[type="submit"] {
    background-color: #930000;
    color: #fff;
    width: 150px;
    height: 33px;
    border-radius: 25px;
  }
  
  #form-container .form-check-input:checked {
    background-color: #930000 !important;
  }
  
  #form-container .form-check-label {
    margin-left: 15px !important;
  }
  
  @media (max-width: 768px) {
    main > div {
      flex-direction: column-reverse;
    }
  
    main > div > div:nth-child(2){
      padding-top: 0;
      margin-bottom: 35px;
    }
  
    main#contact-us > div > div:nth-child(1) {
      display: flex;
    }
  } */

.nav-wrapper {
    width: 100%;
    height: 10vw;
    display: flex;
    transition: all 0.25s ease-out 0s;
    z-index: 99;
}

.left-container {
    flex: 1 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.right-container {
    flex: 1 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.toggler {
    display: none;
    width: 7vw;
    margin-left: 5vw;
}

.menu-container {
    display: flex;
    justify-content: center;
    margin-left: 5vw;
}

.menu {
    margin: 0 2vw;
    color: #C4C4C4;
    font-size: 1vw;
}

.menu:hover {
    color: #930000;
    text-decoration: none;
}

.logo {
    margin-right: 10vw;
    width: 5vw;
}

.sidebar-container {
    display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.sidebar {
    position: absolute;
    height: 100%;
    width: 90vw;
    left: -90vw;
    background-color: #930000;
    transition: all 0.2s ease-out;
}

.sidebar-head {
    height: 15%;
    display: flex;
}

.sidebar-logo-container {
    flex: 1 1;
    display: flex;
    align-items: center;
}

.sidebar-logo {
    height: 9vw;
    margin-left: 7vw;
}

.close-button-container {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.close-button {
    height: 5vw;
    margin-right: 7vw;
}

.sidebar-menu-container {
    display: flex;
    flex-direction: column;
    padding-left: 7vw;
}

.side-menu {
    display: flex;
    align-items: center;
    font-size: 4vw;
    color: #C4C4C4;
    height: 8vh;
}

.side-menu:active,
.side-menu:focus
{
    text-decoration: none;
}

@media (orientation: portrait) {

    .nav-wrapper {
        height: 10vh;
    }

    .toggler {
        display: inline;
    }

    .menu-container {
        display: none;
    }

    .logo {
        width: 12vw;
    }
}

.footer-container {
  position: relative;
  height: 40vh;
  overflow: hidden;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
  height: 20vh;
}

address {
  margin: 0!important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 1vw;
  color: black;
  text-align: right;
}

footer h4 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: black;
  margin-bottom: 25px;
}

footer ul {
  padding: 0 !important;
  margin: 0;
}

footer li {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  margin-bottom: 2vh;
  list-style: none;
}

footer li a,
footer li a:hover,
footer li a:active {
  color: black;
  text-decoration: none;
}

footer span {
  display: block;
  color: black;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
}

.footer-link-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.link {
  font-size: 1vw;
  font-weight: normal;
}

.socmed-container {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
}

.external-link {
  max-width: 50%;
}

.socmed-logo {
  
  width: calc(1.5vw + 1.5vh / 2);
}

.container-1 {
  height: 100%;
}
.container-2 {
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 3vh 5vw;
}

.logo-address-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.logo-footer {
  width: 7vw;
  /* margin-left: 200px; */
}

@media (orientation: portrait) {

  .footer-container {
    height: 30vh;
  }

  footer {
    height: 16vh;
  }

  address {
    font-size: 2vw;
  }

  .link {
    font-size: 2vw;
    font-weight: bold;
  }

  .socmed-logo {
    width: calc(3vw + 3vh / 2);
  }

  .logo-footer {
    width: calc(10vw + 10vh / 2);
  }

}

