.footer-container {
  position: relative;
  height: 40vh;
  overflow: hidden;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
  height: 20vh;
}

address {
  margin: 0!important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 300;
  font-size: 1vw;
  color: black;
  text-align: right;
}

footer h4 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: black;
  margin-bottom: 25px;
}

footer ul {
  padding: 0 !important;
  margin: 0;
}

footer li {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  margin-bottom: 2vh;
  list-style: none;
}

footer li a,
footer li a:hover,
footer li a:active {
  color: black;
  text-decoration: none;
}

footer span {
  display: block;
  color: black;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
}

.footer-link-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.link {
  font-size: 1vw;
  font-weight: normal;
}

.socmed-container {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
}

.external-link {
  max-width: 50%;
}

.socmed-logo {
  
  width: calc(1.5vw + 1.5vh / 2);
}

.container-1 {
  height: 100%;
}
.container-2 {
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 3vh 5vw;
}

.logo-address-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.logo-footer {
  width: 7vw;
  /* margin-left: 200px; */
}

@media (orientation: portrait) {

  .footer-container {
    height: 30vh;
  }

  footer {
    height: 16vh;
  }

  address {
    font-size: 2vw;
  }

  .link {
    font-size: 2vw;
    font-weight: bold;
  }

  .socmed-logo {
    width: calc(3vw + 3vh / 2);
  }

  .logo-footer {
    width: calc(10vw + 10vh / 2);
  }

}
